<template>
  <div class="index">
    <div class="banner">
      <div class="container banner-desc">
        <div class="row">
          <div class="col-8 col-sm-6 offset-md-1">
            <div class="banner-text">
              <h2>让<span>数据</span>赋能学校<span>管理</span></h2>
              <p>齐进步成绩分析系统是公平、公开的教学效果评价系统，<br/>将助力您提高学校的数字治理水平，<br/>帮助您打造积极进取的师资团队。</p>
            </div>
            <div class="d-flex">
              <a-button type="primary" class="btn-color-liner btn-size-banner" @click="goProduct">了解更多</a-button>
              <a-button ghost class="btn-size-banner" @click="goFree">立即试用</a-button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="main-content">

      <div class="content d-flex justify-content-center">
        <div class="row container d-flex justify-content-between align-items-center">
          <div class="col-xl-5 col-sm-12 card-bottom">
            <h3>量化评价教学效果</h3>
            <p style="max-width:16em;">为您提供经过实际使用验证有效的教学效果评价定量指标</p>
            <a-button ghost type="primary" class="btn-size-banner" @click="goProduct">了解更多</a-button>
          </div>
          <div class="col-xl-7 col-sm-12">
            <img src="@/assets/index/index_01_pic@2x.png" alt="">
          </div>
        </div>
      </div>

      <div class="content d-flex justify-content-center bg1">
        <div class="row container  d-flex justify-content-between align-items-center">
          <div class="col-xl-6 col-sm-12">
            <img src="@/assets/index/index_02_pic@2x.png" alt="">
          </div>

          <div class="col-xl-5 col-sm-12 card-top card-bottom">
            <h3>起点-终点过程评价体系</h3>
            <p>更符合教学过程本身的“起点-终点” <br class="br-show-model" />定量评价指标体系</p>
            <a-button ghost type="primary" class="btn-size-banner" @click="goProduct">了解更多</a-button>
          </div>
        </div>
      </div>
      <div class="content d-flex justify-content-center">
        <div class="row container  d-flex justify-content-between align-items-center">
          <div class="col-xl-5 col-sm-12 card-bottom">
            <h3>聚合管理</h3>
            <p style="max-width:16em;">统筹管理教师任教周期内每次考试的学生侧数据和教师侧数据，展示完整的教师业绩曲线</p>
            <a-button ghost type="primary" class="btn-size-banner" @click="goProduct">了解更多</a-button>
          </div>

          <div class="col-xl-7 col-sm-12">
            <img src="@/assets/index/index_03_pic@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content d-flex flex-wrap justify-content-center bg-blue">
        <div class="content-header text-white text-center">
          <h4>多维评价</h4>
          <p>提供多种维度和评价工具，既奖励优秀集体，<br class="br-show-model" />也奖励优秀老师，更奖励“低进高出”</p>
        </div>
        <div class="row container  d-flex justify-content-between align-items-start">
          <div class="col-xl-3 col-sm-12 card-bottom">
            <img src="@/assets/index/index_04_pic_01@2x.png" alt="" />
            <p class="text-chart text-white text-center">科目优势分析</p>
          </div>
          <div class="col-xl-3 col-sm-12 card-bottom">
            <img src="@/assets/index/index_04_pic_02@2x.png" alt="" />
             <p class="text-chart text-white text-center">发掘先进老师</p>
             <span class="text-tips">表彰先进有理有据</span>
          </div>
          <div class="col-xl-3 col-sm-12 card-bottom">
            <img src="@/assets/index/index_04_pic_03@2x.png" alt="" />
             <p class="text-chart text-white text-center">多维评价班集体</p>
             <span class="text-tips">鼓励所有分段的班集体你追我赶</span>
          </div>
        </div>
      </div>

      <!-- 新闻咨询 -->
      <div class="content d-flex justify-content-center">
        <div class="news-list container">
          <h5 class="text-center">新闻资讯</h5>
          <div v-for="(item,i) in dataSource" :key="i" class="news-item row d-flex" :class="{'last':i === 3}">
            <div class="col-xl-4 col-sm-12">
              <img :src="item.cover" alt="" />
            </div>
            <div class="col-xl-8 col-sm-12">
              <div class="news-item-detail">
                <p class="time">{{item.create_time}}</p>
                <p class="title">{{item.title}}</p>
                <p class="desc ellipsis">{{item.short_desc}}</p>
              </div>
            </div>
          </div>
          <div class="text-center news-footer">
            <a-button ghost type="primary" class="btn-size-banner" @click="goProduct">了解更多</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { articleList } from "@/services/news";
export default {
  data() {
    return {
      dataSource: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    goProduct() {
      window.scroll(0, 0);
      this.$router.push({
        name:'Product'
      })
    },
    goFree(){
      window.scroll(0, 0);
      this.$router.push({
        name:'Free'
      })
    },
    getList() {
      articleList({
        pageNo:1,
        pageSize:10,
      }).then(res =>{
        const { code, msg } = res.data;
        if (code === 200) {
          const { data } = res.data.data;
          this.dataSource = data;
        }else{
          this.$message.error(msg);
        }

      })
    },
  },
}
</script>
<style lang="less" scoped>
  .banner{
    background:url(../../assets/index/banner.png) no-repeat;
    background-size: 100% auto;
    .banner-desc{
      padding:100px 0 130px 0;
      color:#fff;
      .banner-text{
        position: relative;
        padding-left:40px;
        &:before{
          display:block;
          content:'';
          width:13px;
          height:181px;
          background: linear-gradient(108deg, @primary-color 0%, #ECB500 100%);
          border-radius: 4px;
          position: absolute;
          top:15px;
          left:0;
        }
      }

      h2{
        font-size:45px;
        font-weight:normal;
        padding-bottom:36px;
        color: #FFFFFF;
        span{
          color:@primary-color;
        }
      }
      p{
        font-size:20px;
        line-height:37px;
        margin-bottom:83px;
      }
    }
  }
  .main-content{
    .content{
      padding:70px 0;
      img{
        display:block;
        max-width:100%;
      }
      h3{
        font-size: 30px;
        color: #222222;
        padding-bottom:23px;
        position: relative;
        margin-bottom:25px;
        &:after{
          display:block;
          content:'';
          width:24px;
          height:4px;
          background: @primary-color;
          position: absolute;
          bottom:0;
          left:0;
        }
      }
      p{
        margin-bottom:50px;
        line-height:2;
      }
      p.text-chart{
        padding-top:30px;
        font-size:18px;
        margin-bottom:0;
      }
      span.text-tips{
        display: block;
        text-align: center;
        color:#fff;
        opacity:0.6;
        font-size:14px;
      }
      .content-header{
        width:100%;
        h4{
          color:#fff;
          padding-bottom:23px;
          position: relative;
          &::after{
            display:block;
            content: "";
            width: 24px;
            height: 4px;
            background: #EC6300;
            position: absolute;
            bottom:0;
            left:50%;
            margin-left:-10px;
          }
        }
        p{
          padding-top:10px;
          opacity:0.8;
        }
      }
    }
    .bg1{
      background:url(../../assets/index/index_02_bg@2x.png) no-repeat;
      background-size:100% 100%;
      padding-bottom:0;
    }
    .bg-blue{
      background: linear-gradient(132deg, #026EC0 0%, #00317D 100%);
    }
  }

  .br-show-model{
    display:none;
  }

  @media screen and (max-width: 991px) {
     .banner{
        .banner-desc{
          padding-top:50px;
          padding-bottom:20px;
          .banner-text{
            &:before{
              width:8px;
              height:145px;
            }
          }
          h2{
            font-size:30px;
            padding-bottom:20px;
          }
          p{
            font-size:14px;
            margin-bottom:20px;
          }
        }
     }
     .card-bottom{
      margin-bottom:40px;
      text-align: center;
     }
     .card-top{
      margin-top:40px;
      text-align: center;
     }
     .main-content{
      .content{
        h3{
          &:after{
            left: 50%;
            margin-left: -12px;
          }
        }
        p{
          max-width:100% !important;
          margin-bottom:20px;
        }
        img{
          width:100%;
        }
      }
     }
  }


  @media screen and (max-width: 575px) {
       .banner{
        background-size:100% 100%;
        .banner-desc{
          padding-top:20px;
          padding-bottom:20px;
          .banner-text{
            padding-left: 20px;
            max-width: 220px;
            &:before{
              display:none;
            }
          }
          h2{
            font-size:18px;
            padding-bottom:0px;
          }
          p{
            font-size:12px;
            margin-bottom:20px;
            line-height:1.5;
            br{
              display:none;
            }
          }
        }
     }

     .btn-size-banner{
      width:80px;
      height:30px;
      margin-left: 20px;
      margin-right:0;
     }

     .br-show-model{
        display:block;
      }
  }


</style>
